<script>
import Loader from "vue-element-loading";
import Vue from "vue";

import api from "@/api";
export default {
  components: {
    Loader,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    validate() {
      this.loading = true;
      const url = `/validation/`;

      const data = {
        validationId: this.validationId,
      };

      api.Util.axios
        .post(url, data)
        .then(() => {
          Vue.$toast.success("Validation successful. You may login.");
          this.$router.push({ name: "Login" });
        })
        .catch((err) => {
          if (err.response.data) {
            Vue.$toast.error("Could not validate: " + err.response.data);
          } else {
            Vue.$toast.error("Unable to validate.");
          }
          this.$router.push({ name: "RehearsalSpaces" });
        });
    },
  },
  computed: {
    validationId() {
      return this.$route.params.valId;
    },
  },
  created() {
    this.validate();
  },
};
</script>
<template>
  <div id="withId">
    <div class="container">
      <div class="row mt-3">
        <div class="col" id="inner">
          <Loader :active="loading" />
          <div>Validating...</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
#inner {
  min-height: 100px;
  background: white;
}
</style>
